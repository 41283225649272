import {
    Alert,
    App,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Flex,
    Form,
    Image,
    Popconfirm,
    Row,
    Select,
    Tag,
    Tooltip,
    Typography
} from 'antd';
import { SyncOutlined, DisconnectOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSupabase } from '../AuthProvider';
import { useLogSnag } from '@logsnag/react';

type PcoConnection = {
    key: string,
    organization_name: string,
    status: string,
    service_types: {
        label: string;
        value: string;
    }[],
    preferences: {
        services: [],
    }
    timezone: string,
}

const Status = ({
    connectionStatus,
    pcoOauthLink
}: {
    connectionStatus: string,
    pcoOauthLink: string
}) => {
    switch (connectionStatus) {
        case 'connected':
            return (
                <Tag color="green">Connected</Tag>
            );
        case 'disconnected':
            return (
                <Button href={pcoOauthLink}>Connect</Button>
            );
        case 'pending':
            return (
                <Tag icon={<SyncOutlined spin />} color="processing">Connecting...</Tag>
            );
        default:
            return (
                <Tag icon={<SyncOutlined spin />}></Tag>
            );
    }
}

const ConnectionDetails = ({
    connection,
    disconnectPco,
    update
}: {
    connection: PcoConnection | undefined,
    disconnectPco(): void,
    update(): void
}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const supabase = useSupabase();
    const { message } = App.useApp();

    async function saveForm() {
        setLoading(true);
        await supabase.functions.invoke('postPcoPreferences', {
            body: {
                form: form.getFieldsValue()
            }
        }).then(res => {
            setLoading(false);
            update();
        }).catch(err => {
            message.error(err.message);
            setLoading(false);
        })
    }

    useEffect(() => {
        form.setFieldsValue(connection?.preferences);
    })

    return (
        <>
            <Divider />
            <Row gutter={[16, 16]}>
                <Col sm={{ span: 8 }}>
                    <Card
                        title="Services"
                        size='small'
                        extra={<Image preview={false} src="../imgs/pco_Services.png" width={30} style={{ padding: 4 }} />}
                    >
                        <Form
                            form={form}
                            onFinish={saveForm}
                        >
                            <Form.Item name={'services'} extra="Select the service types you'd like to include.">
                                <Select
                                    allowClear
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                    placeholder="Select service types"
                                    options={connection?.service_types}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={loading}>Save</Button>
                            </Form.Item>
                        </Form>
                        <Alert message="Services without a Title or Series Title won't be displayed" type="warning" />
                    </Card>
                </Col>
                <Col sm={{ span: 8 }}>
                    <Card
                        title="Calendar"
                        size='small'
                        extra={<Image preview={false} src="../imgs/pco_Calendar.png" width={30} style={{ padding: 4 }} />}
                    >
                        Coming Soon
                    </Card>
                </Col>
                <Col sm={{ span: 8 }}>
                    <Card size='small'>
                        <Descriptions column={1} colon>
                            <Descriptions.Item label="Account Name">
                                <Tooltip title='This data is set by Planning Center and can be updated by a Planning Center Admin.'>
                                    {connection?.organization_name}
                                </Tooltip>
                            </Descriptions.Item>
                            <Descriptions.Item label="Account Timezone">
                                <Tooltip title='This data is set by Planning Center and can be updated by a Planning Center Admin.'>
                                    {connection?.timezone}
                                </Tooltip>
                            </Descriptions.Item>
                        </Descriptions>
                        <Divider style={{ margin: 4, padding: 4 }} />
                        <Popconfirm
                            title="Disconnect"
                            description="Are you sure you want to disconnect?"
                            onConfirm={disconnectPco}
                            okText="Yes, Disconnect."
                            cancelText="No"
                            okButtonProps={{ danger: true }}
                            icon={<DisconnectOutlined style={{ color: 'red' }} />}
                        >
                            <Button danger>
                                Disconnect
                            </Button>
                        </Popconfirm>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

function Connections() {
    const [connectionStatus, setConnectionStatus] = useState('loading');
    const [pcoConnection, setPcoConnection] = useState<PcoConnection>();
    const CLIENT_ID = 'dfea6677013dfae279b439047f9a720b36e3c3188bf0ad9ec73c8d5da90e9911';
    const REDIRECT_URL = 'https://streamschedule.app/connections';
    const pcoOauthLink = "https://api.planningcenteronline.com/oauth/authorize?client_id=" + CLIENT_ID + "&redirect_uri=" + REDIRECT_URL + "&response_type=code&scope=services+people"
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code");
    const supabase = useSupabase();
    const { track, } = useLogSnag();

    async function pcoAuth(code: string) {
        setConnectionStatus('pending');
        const { data } = await supabase.functions.invoke('pcoAuth', {
            body: {
                pco_code: code
            }
        })

        if (data) {
            searchParams.delete('code');
            // Track an event
            track({
                channel: "connections",
                event: "Connected to PCO",
                icon: "🚀",
                notify: false,
            });
            getPcoStatus();
        } else {
            // TODO: throw notif
            setConnectionStatus('disconnected')
        };
    }

    async function getPcoStatus() {
        const { data } = await supabase.functions.invoke('getPcoStatus')
        setPcoConnection(data);
        setConnectionStatus(data.status)
    }

    async function disconnectPco() {
        const { data } = await supabase.functions.invoke('pcoDisconnect')
        setConnectionStatus(data.status)
        setPcoConnection(undefined);
    }

    // Handle PCO Code.
    useEffect(() => {
        if (code) {
            pcoAuth(code)
        } else {
            getPcoStatus();
        }
    }, []);

    return (
        <Flex vertical gap={8}>
            <Divider orientation="left" orientationMargin="0">
                <Typography.Title level={4} style={{ margin: 0 }}>Connections</Typography.Title>
            </Divider>
            <Card>
                <Flex justify='space-between' align='center' gap={8}>
                    <Image src='../imgs/planning-center-white.png' width={200} preview={false} />
                    <Status connectionStatus={connectionStatus} pcoOauthLink={pcoOauthLink} />
                </Flex>
                <Typography.Text style={{ margin: 0 }}>
                    Connect with Planning Center to automatically load upcoming events from Planning Center Services.
                </Typography.Text>
                {(connectionStatus === 'connected')
                    ? <ConnectionDetails connection={pcoConnection} disconnectPco={() => disconnectPco()} update={getPcoStatus} />
                    : null
                }
            </Card>
            <Alert message="Calendar Integrations, CSV Uploads, and more Coming Soon!" type="info" />
        </Flex>
    );
}

export default Connections;

import React, { useState } from 'react';
import {
  ApiOutlined,
  FormatPainterOutlined,
  DashboardOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  XOutlined,
  MailOutlined,
  DownOutlined,
  UserOutlined,
  NotificationOutlined
} from '@ant-design/icons';
import {
  Alert,
  App,
  Button,
  Dropdown,
  Flex,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Space,
  theme
} from 'antd';
import { Logo } from '../components/logo';
import { useAuth } from '../AuthProvider';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Header } from 'antd/es/layout/layout';
import Link from 'antd/es/typography/Link';
import Updates from './sections/updates';

const { Content, Footer, Sider } = Layout;

const menuItems = [
  {
    key: 'dashboard',
    icon: <DashboardOutlined />,
    label: `Dashboard`,
  },
  {
    key: 'customize',
    icon: <FormatPainterOutlined />,
    label: `Customize`,
  },
  {
    key: 'connections',
    icon: <ApiOutlined />,
    label: `Connections`,
  },
];

const BaseLayout: React.FC = () => {
  const location = useLocation();
  const { pathname, } = location;
  const currentRoute = pathname.split('/')[1]
  const [collapsed, setCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  let auth = useAuth();
  let navigate = useNavigate();
  const menuNavigate: MenuProps['onClick'] = (e) => {
    navigate(e.key);
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const close = () => {
    setIsModalOpen(false);
  };

  return (
    <App>
      <Layout hasSider>
        <Sider
          theme='light'
          breakpoint="lg"
          collapsedWidth="0"
          trigger={null}
          collapsible
          collapsed={collapsed}
          style={{
            paddingBlock: 24,
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0
          }}
        >
          <Logo variant='light' />
          <Menu
            mode="inline"
            defaultSelectedKeys={[currentRoute]}
            items={menuItems}
            onClick={menuNavigate}
            style={{border: 'none'}}
          />
        </Sider>
        <Layout
          style={!collapsed ? {
            marginLeft: 200,
            minWidth: 400,
          } : {
            minWidth: 400,
          }}
        >
          <Header style={{ paddingInlineStart: 0, background: colorBgContainer, }}>
            <Flex justify='space-between'>
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                style={{
                  fontSize: '16px',
                  width: 64,
                  height: 64,
                }}
              />
              <Space>
                <Dropdown
                  placement='bottomRight'
                  menu={{
                    items: [
                      {
                        key: '1',
                        icon: <UserOutlined />,
                        label: (
                          <a href="profile">
                            Update Account
                          </a>
                        ),
                      },
                      {
                        key: '2',
                        danger: true,
                        icon: <LogoutOutlined />,
                        label: 'Logout',
                        onClick: () => {
                          auth.signOut(() => navigate("/login"));
                        }
                      },
                    ]
                  }}
                >
                  <Space>
                    {auth.profile.name}
                  </Space>
                </Dropdown>
                <Button
                  shape='circle'
                  type='text'
                  onClick={showModal}
                >
                  <NotificationOutlined />
                </Button>
              </Space>
            </Flex>
          </Header>
          {/* CONTENT */}
          <Alert message={<Flex justify='space-between'>
            Thank you for being an Beta Tester!
            <Space size={'small'}>
              Share Feedback:
              <Link href="https://twitter.com/i_spy_coffee" target='_blank'>
                <XOutlined />
              </Link>
              <Link href="mailto:adam@magicmirrorcreative.com" target='_blank'>
                <MailOutlined />
              </Link>
            </Space>
          </Flex>} icon="🚀" type='info' banner />
          <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            <div
              style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Outlet />
            </div>
          </Content>
          <Footer style={{ textAlign: 'center' }}>
            ©{new Date().getFullYear()} Designed by <a href='https://twitter.com/i_spy_coffee' rel='noreferrer' target="_blank">Adam</a>
          </Footer>
        </Layout>
      </Layout>
      <Modal
        title="Recent Updates"
        open={isModalOpen}
        onOk={close}
        onCancel={close}
        footer={[
          <Button onClick={close}>
            Yipee! 🎉
          </Button>
        ]}
      >
        <Updates />
      </Modal>
    </App>
  );
};

export default BaseLayout;
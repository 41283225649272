import { Card, Col, Flex, Image, Row, Space, Typography } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth, useSupabase } from "../AuthProvider";
import { useLogSnag } from '@logsnag/react';

const dark = {
    bg: '#100F0F',
    bg2: '#1C1B1A',
    ui: '#282726',
    ui2: '#343331',
    ui3: '#403E3C',
    tx3: '#575653',
    tx2: '#878580',
    tx: '#CECDC3',
    re: '#AF3029',
    or: '#BC5215',
    ye: '#AD8301',
    gr: '#66800B',
    cy: '#24837B',
    bl: '#205EA6',
    pu: '#5E409D',
    ma: '#A02F6F',
}

let theme = dark;

const light = {
    bg: '#FFFCF0',
    bg2: '#F2F0E5',
    ui: '#E6E4D9',
    ui2: '#DAD8CE',
    ui3: '#CECDC3',
    tx3: '#B7B5AC',
    tx2: '#6F6E69',
    tx: '#100F0F',
    re: '#D14D41',
    or: '#DA702C',
    ye: '#D0A215',
    gr: '#879A39',
    cy: '#3AA99F',
    bl: '#4385BE',
    pu: '#8B7EC8',
    ma: '#CE5D97',
}

let curDate: Date | null = null;

const EventCard = ({
    event,
    additionalCss,
    preferences,
} : {
    event: AnyObject,
    additionalCss: AnyObject,
    preferences: AnyObject | undefined,
}) => {
    const date = new Date(event.datetime);
    const highlighted = (date.getTime() < (Date.now() + 1000 * 60 * 60 * 24 * 7));
    let printDate = true;
    let timeDisplay = '';

    console.log(preferences);

    if (preferences?.time === '12hr') {
        let hours = date.getUTCHours();
        let minutes = date.getUTCMinutes();
        let meridiem = hours >= 12 ? 'pm' : 'am';
        if (hours === 0) hours = 12;
        timeDisplay = String(hours).padStart(2, '0')+':'+String(minutes).padStart(2, '0')+' '+meridiem;
    } else {
        timeDisplay = String(date.getUTCHours()).padStart(2, '0')+':'+String(date.getUTCMinutes()).padStart(2, '0');
    }

    if (!curDate) {
        curDate = date
        printDate = true;
    } else if (curDate.toDateString()!== date.toDateString()) {
        printDate = true;
    };

    if (!(event.series || event.title)) {
        return <></>;
    }

    return (
        <>
            {printDate && <DateHeader
                date={date.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric"})}
                fontFamily={additionalCss.fontFamily}
            />}
            <Card style={{
                backgroundColor: theme.bg2,
                color: theme.tx,
                borderColor: (highlighted) ? theme.ye : theme.ui2,
                borderWidth: 2,
                padding: 4,
                ...additionalCss,
            }} size="small">
                <Row align={"middle"} gutter={24}>
                    <Col>
                        <span
                            style={{
                                fontSize: '2rem',
                                ...additionalCss,
                            }}
                        >
                            {timeDisplay}
                        </span>
                    </Col>
                    <Col span={'flex'}>
                        <h2 style={{
                            margin: 0,
                            padding: 0,
                            lineHeight: 1,
                            color: theme.tx,
                            fontSize: '2rem',
                            ...additionalCss,
                        }}>
                            {event.series && event.series}
                            {(event.series && event.title) ? ' - ' : ''}
                            {event.title ?? ''}</h2>
                        <span style={{
                            color: theme.tx2,
                        }}>{event.description}</span>
                    </Col>
                </Row>
            </Card>
        </>
    )
}
const DateHeader = ({
    date,
    fontFamily,
} : {
    date: string,
    fontFamily: string,
}) => {
    return (
        <p style={{
            color: theme.tx2,
            margin: 4,
            fontFamily: fontFamily,
            fontSize: '2em',
        }}>{date}</p>
    )
}

function View() {
    const [theme, setTheme] = useState(dark);
    const [additionalCss, setAdditionalCss] = useState({});
    const [loading, setLoading] = useState(true);
    const [roomPref, setRoomPreferences] = useState<AnyObject>();
    const [events, setEvents] = useState<AnyObject>();
    const [searchParams] = useSearchParams();
    const roomId = searchParams.get("id");
    const supabase = useSupabase();
    const { user } = useAuth();
    const { track, } = useLogSnag();

    async function getEvents(roomId: string) {
        supabase.functions.invoke('getEvents', {
            body: {
                room_id: roomId
            }
        }).then((res) => {
            setEvents(res.data);
        });
    };

    async function getRoom(roomId: string) {
        supabase.functions.invoke('getRoom', {
            body: {
                room_id: roomId
            }
        }).then(({data}) => {
            setRoomPreferences(data);
            if (data.theme === 'light') {
                setTheme(light);
            }
            let css: AnyObject = {
                fontFamily: data.font,
            };
            if (data.border === 'straight') {
                css.borderRadius = 0;
            }
            setAdditionalCss(css);
            setLoading(false);
            getEvents(roomId);
        });
    };

    useEffect(() => {
        if (user) {
            track({
                channel: "views",
                event: "Opened View",
                icon: "👀",
                notify: false,
            });
        }
        if (roomId) getRoom(roomId);
    }, [])

    return (
        <Row style={{
            backgroundColor: theme.bg,
            color: theme.tx,
            overflow: 'hidden',
            height: '100vh'
        }} align='middle'>
            <Col flex={'auto'} style={{padding: '10vh 6vw'}}>
                <Flex justify="center" align="center" style={{ minHeight: '40vh' }}>
                    {!loading && <Image
                        src={roomPref?.logo_url}
                        preview={false}
                        style={{
                            width: '24vw',
                            minWidth: 100,
                            maxWidth: 600,
                        }}
                    />}
                </Flex>
            </Col>
            {events && 
                <Col flex={2} style={{ maxHeight: '100vh', padding: '10vh 6vw'}}>
                    <Space direction="vertical" size='large' style={{width: '100%'}}>
                        <Typography.Title level={2} style={{
                            color: theme.tx,
                            margin: 0,
                            padding: 0,
                            fontSize: '2.5rem',
                        }}>Coming Up:</Typography.Title>
                        {events?.map((event: AnyObject, index: number) => (
                            <EventCard
                                key={index}
                                event={event}
                                additionalCss={additionalCss}
                                preferences={roomPref}
                            />
                        ))}
                    </Space>
                </Col>
            }
        </Row>
    );
}


export default View;
import React, { useEffect } from "react";
import { AnyObject } from "antd/es/_util/type";
import { useState } from 'react'
import { createClient } from '@supabase/supabase-js'
import Login from "./pages/login";
import { useLogSnag } from '@logsnag/react';

interface AuthContextType {
  session: AnyObject;
  user: AnyObject;
  profile: AnyObject;
  signInWithEmail: (user: AnyObject, callback: VoidFunction) => void;
  signUpNewUser: (user: AnyObject, callback: VoidFunction) => void;
  signOut: (callback: VoidFunction) => void;
  updateProfile: (userData: AnyObject) => void;
}
  
let AuthContext = React.createContext<AuthContextType>(null!);

const supabase = createClient(
  'https://zghhvqvkxueerljuulqk.supabase.co',
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpnaGh2cXZreHVlZXJsanV1bHFrIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI1MjA1NjAsImV4cCI6MjAyODA5NjU2MH0.Dt0olCKKFq8A7Zy4ASdpaUIiZeluDb8yqTyOS3UyzCI'
)
  
export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [session, setSession] = useState<any>(null);
  let [user, setUser] = useState<any>(null);
  let [profile, setProfile] = useState<any>(null);
  let [loading, setLoading] = useState(false);
  const { setUserId, identify } = useLogSnag();

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data: { user: currentUser } } = await supabase.auth.getUser();
      if (currentUser) {
        const { data: userProfile } = await supabase
          .from('profiles')
          .select()
          .eq('id', currentUser?.id)
          .single();

        setProfile(userProfile);
        setUserId(currentUser.id);
      }

      setUser(currentUser ?? null);
      setLoading(false);
    };
    getUser();

    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'USER_UPDATED') {
        // TODO: I'm not sure if we want to set this...
        // setSession(session);
        setUser(session?.user);
      }
    })
  }, []);

  async function signUpNewUser(user: AnyObject, callback: VoidFunction) {
    const { data, error } = await supabase.auth.signUp({
      email: user.email,
      password: user.password,
      options: {
        data: {
          name: user.name
        }
      }
    })

    if (error) {
      console.error(error)
    } else if (data) {
      identify({
        user_id: data.user?.id,
        properties: {
          name: user.name,
          email: user.email,
        }
      });

      setSession(data.session);
      setUser(data.user);
    }

    callback();
  }

  async function signInWithEmail(user: AnyObject, callback: VoidFunction) {
    const { data, error } = await supabase.auth.signInWithPassword({
      email: user.email,
      password: user.password,
    })

    if (error) {
      console.error(error)
    } else if (data) {
      // Set the user id when a user logs in
      setSession(data.session);
      setUser(data.user);
      callback();
    }
  }

  async function signOut(callback: VoidFunction) {
    await supabase.auth.signOut()
    callback();
  }

  async function updateProfile(userData: AnyObject) {
    const { data: userProfile } = await supabase
      .from('profiles')
      .update(userData)
      .eq('id', user.id)
      .select()
      .single();

    setProfile(userProfile);
  }

  let value = { session, user, profile, supabase, signInWithEmail, signOut, signUpNewUser, updateProfile };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return React.useContext(AuthContext);
}

export function useSupabase() {
  return supabase;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let { user } = useAuth();

  
  return (
    <>
      {user ? children : <Login />}
    </>
  )
}
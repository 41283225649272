import {
    App,
    Button,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Typography
} from 'antd';
import { useEffect, useState } from 'react';
import { useAuth } from '../AuthProvider';

function Profile() {
    const auth = useAuth();
    const [profileForm] = Form.useForm();
    const [formLoading, setFormLoading] = useState(false)
    const { message } = App.useApp();

    useEffect(() => {
        profileForm.setFieldsValue({
            name: auth.profile.name,
            email: auth.user.email,
        })
    })

    async function submit() {
        setFormLoading(true);
        auth.updateProfile({
            name: profileForm.getFieldValue('name')
        })

        message.success('Success');
        setFormLoading(false);

        // if (profileForm.getFieldValue('email') !== auth.user.email) {
        //     const { error } = await supabase.auth.updateUser({
        //         email: profileForm.getFieldValue('email')
        //     })

        //     if (error) {
        //         console.error(error.message);
        //     }
        // }
    }

    return (
        <>
            <Divider orientation="left" orientationMargin="0">
                <Typography.Title level={4} style={{ margin: 0 }}>Your Account</Typography.Title>
            </Divider>
            <Row gutter={24}>
                <Col span={12}>
                    <Form
                        layout='vertical'
                        style={{ maxWidth: 600 }}
                        form={profileForm}
                        onFinish={submit}
                        initialValues={{
                            // ...auth.user.user_metadata,
                            scheme: 'dark',
                            border_style: 'rounded',
                            font_style: 'serif',
                        }}
                    >
                        <Form.Item name='name' label='Name' >
                            <Input style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item name='email' label='Email' >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={formLoading}>
                                Save Account
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={12}>
                    {/* <Card title='Danger Zone'>
                        <Typography.Paragraph>
                            Sometimes it's just not meant to be, but if you're experiencing problems that I can help with,
                            please don't hesitate to <Tag><a href='mailto:adam@magicmirrorcreative.com'>Contact Me</a></Tag>
                        </Typography.Paragraph>
                        <Popconfirm
                            title="Delete Account"
                            description="Are you sure to delete your account?"
                            // onConfirm={confirm}
                            okText="Yep, Delete"
                            cancelText="Nevermind"
                            icon={<DeleteTwoTone twoToneColor='red'/>}
                            okButtonProps={{
                                danger: true,
                            }}
                        >
                            <Button danger>Delete Account</Button>
                        </Popconfirm>
                    </Card> */}
                </Col>
            </Row>
        </>
    );
}

export default Profile;

import { Flex, Typography } from "antd"

export function Logo({
    variant
}: {
    variant: "light" | "dark"
}) {
    const textColor = (variant === "dark") ? "#000000" : "#ffffff";

    return (
        <>
            <Flex vertical gap={0} align="center">
                <Typography.Title style={{margin: 0, padding: 0, color: textColor}}>Stream</Typography.Title>
                <Typography.Text color={textColor} style={{margin: 0, padding: 0, color: textColor}}>Schedule</Typography.Text>
            </Flex>
        </>
    )
}
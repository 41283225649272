import { Button, Divider, Flex, Space, Steps, Tag, Tooltip, Typography } from 'antd';
import { ApiOutlined, FormatPainterOutlined, ExportOutlined } from '@ant-design/icons';
import { useAuth, useSupabase } from '../AuthProvider';
import { useEffect, useState } from 'react';

function Dashboard() {
    const { Text, Title } = Typography;
    const supabase = useSupabase();
    const { profile } = useAuth();
    const [roomCode, setRoomCode] = useState('');
    const [step, setStep] = useState([1]);
    
    async function getConnection() {
        console.log('getting connection...')
        await supabase.from('user_connections')
            .select('id, organization_name, organization_timezone')
            .limit(1)
            .single()
            .then((res) => {
                setRoomCode(res?.data?.id.toString(16).padStart(4, 0))
                setStep([...step, 2]);
            });
    }

    useEffect(() => {
        getConnection();
        if (profile.organization_name || profile.organization_logo) {
            setStep([...step, 3]);
        }
    }, []);

    return (
        <>
            <Flex justify='space-between' align='center' wrap='wrap'>
                <Space>
                    <Title> Welcome to StreamSchedule</Title>
                    <Tag>BETA</Tag>
                </Space>
                {roomCode
                    ? <a href={`view?id=${roomCode}`} target='_blank' rel='noreferrer' >
                        <Button type='primary' icon={<ExportOutlined />}>View My Schedule</Button>
                    </a>
                    : <Tooltip title={'Set up a connection with PCO First.'}>
                        <Button disabled icon={<ExportOutlined />}>View My Schedule</Button>
                    </Tooltip>
                }
            </Flex>
            <Space direction='vertical'>
                <Text>StreamSchedule is a tool to empower you to communicate upcoming broadcast times with your online audience.</Text>
            </Space>
            <Divider />
            <Steps
                labelPlacement='vertical'
                size='small'
                current={step.length}
                items={[
                    {
                        title: 'Sign up:',
                        description: 'You did this one already! Great job!',
                    },
                    {
                        title: 'Connect to Planning Center:',
                        description: <>Go to <Tag icon={<ApiOutlined />}><a href="/connections">Connections</a></Tag> to connect to your Planning Center account.</>,
                    },
                    {
                        title: 'Customize your View:',
                        description: <>
                            Upload your logo in <Tag icon={<FormatPainterOutlined />}><a href="/customize">Customize</a></Tag>
                        </>,
                    },
                    {
                        title: 'Copy your Schedule View:',
                        description: <>
                            Copy the link to your schedule. ID # <Text copyable={{ text: 'streamschedule.app/view?id=' + roomCode }}>{roomCode}</Text>
                        </>,
                    },
                    {
                        title: 'Add your Schedule to OBS or ProPresenter',
                        description: 'Or even pull it up on a Smart TV in the lobby!',
                    },
                    {
                        title: 'Drink Coffee ☕️',
                        description: '',
                    },
                ]}
            />
        </>
    );
}

export default Dashboard;

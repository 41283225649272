import { Timeline, Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';
import React from 'react';

const Updates: React.FC = () => {
    return (
        <>
            <Timeline
                pending="Better messaging, help text, and improved usability."
                reverse
                items={[
                    {
                        children: [
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Added your name to the header. Fancy!</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Updated the overall layout (header, collapsible sidebar, etc.)</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Added this "Updates" section</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Fixed Issues with the Timezone on the Schedule View</Typography.Paragraph>,
                        ],
                    },
                    {
                        children: [
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Updates to the Dashboard for Alpha testing Launch</Typography.Paragraph>,
                        ],
                    },
                    {
                        children: [
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Improvements to Sign-Up and Accounts</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>More styling options for Views 🎉</Typography.Paragraph>,
                        ],
                    },
                    {
                        children: [
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Improved dark theme</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Added option to choose 12 or 24 hr time format</Typography.Paragraph>,
                            <Typography.Paragraph style={{ marginBlockEnd: 0 }}>Improved View Rendering</Typography.Paragraph>,
                        ],
                        dot: <StarFilled />,
                    },
                ]}
            />
        </>
    );
};

export default Updates;
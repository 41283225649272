import {
  Routes,
  Route,
} from "react-router-dom";
import Login from "./pages/login";
import { AuthProvider, RequireAuth } from "./AuthProvider";
import BaseLayout from "./pages/baseLayout";
import Dashboard from "./pages/dashboard";
import Customize from "./pages/customize";
import Connections from "./pages/connections";
import Profile from "./pages/profile";
import View from "./pages/view";
import { LogSnagProvider } from "@logsnag/react";
import { ConfigProvider, theme } from "antd";

const darkTheme = {
  algorithm: theme.darkAlgorithm,
  token: {
    colorError: "#d14d41",
    colorWarning: "#da702c",
    colorSuccess: "#879a39",
    colorPrimary: "#4385be",
    colorInfo: "#4385be",
    // colorBgBase: "#fefefe",
    // colorTextBase: "#010101",
    colorBgBase: "#010101",
    colorTextBase: "#fafafa",
    wireframe: false
  },
}

export default function App() {
  return (
    <LogSnagProvider
      token="3fabea742a9d1ecc9906092ad1420600"
      project="stream-schedule"
    >
      <ConfigProvider
        theme={darkTheme}
      >
        <AuthProvider>
          <Routes>
              {/* <Route path="/" element={<p>Website Goes Here</p>} /> */}
              <Route path="/login" element={<Login />} />
              <Route path="/view" element={<View />} />
              <Route
                path="*"
                element={
                  <RequireAuth>
                    <BaseLayout />
                  </RequireAuth>
                }
              >
                <Route
                  index
                  path="*"
                  element={<Dashboard />}
                />
                <Route
                  path="customize"
                  element={<Customize />}
                />
                <Route
                  path="connections"
                  element={<Connections />}
                />
                <Route
                  path="profile"
                  element={<Profile />}
                />
              </Route>
          </Routes>
        </AuthProvider>
      </ConfigProvider>
    </LogSnagProvider>
  );
}
import { useEffect, useState } from 'react';
import { UploadOutlined, SunFilled, MoonFilled } from '@ant-design/icons';
import { App, Button, Divider, Form, Input, Radio, Typography, Upload } from 'antd';
import type { GetProp, UploadProps } from 'antd';
import { useAuth, useSupabase } from '../AuthProvider';
import { AnyObject } from 'antd/es/_util/type';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

function Customize() {
    const supabase = useSupabase();
    const auth = useAuth();
    const [formLoading, setFormLoading] = useState(false);
    const [brandingForm] = Form.useForm();
    const [fileList, setFileList] = useState<any>([]);
    const {message} = App.useApp();

    useEffect(() => {
        if (auth.profile.organization_logo) {
            const { data } = supabase.storage.from('OrganizationLogos').getPublicUrl(auth.profile.organization_logo)
            setFileList([{
                name: auth.profile.organization_logo,
                url: data.publicUrl
            }])
        }
    }, [])

    const beforeUpload = (file: FileType) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
    
        if (isJpgOrPng && isLt2M) {
            setFileList([file]);
        }

        return false;
    };

    async function submitBranding(formData: AnyObject) {
        setFormLoading(true);
        let filePath = null;

        // If a file has been uploaded.
        if (fileList.length > 0) {
            const file = fileList[0];
            // if (profile.organization_logo) {
            //     const { data, error } = await supabase.storage.from('OrganizationLogos').remove([profile.organization_logo]);
            // }

            const { data } = await supabase.storage
                .from('OrganizationLogos')
                .upload(file?.uid+'-'+file?.name, file, {
                    cacheControl: '3600',
                    upsert: false
                })

            filePath = data?.path;
        }

        
        await supabase
            .from('profiles')
            .update({
                organization_name: formData.organization_name,
                organization_logo: filePath ? filePath : auth.profile.organization_logo,
                style_scheme: formData.style_scheme,
                style_border: formData.style_border,
                style_font: formData.style_font,
            })
            .eq('id', auth.user.id)
            .select()
            .then(() => {
                setFormLoading(false);
                message.success('Saved Successfully!');
            });

    }



    return (
        <>
            <Divider orientation="left" orientationMargin="0">
                <Typography.Title level={4} style={{ margin: 0 }}>Branding</Typography.Title>
            </Divider>
            <Form
                layout='vertical'
                style={{ maxWidth: 600 }}
                form={brandingForm}
                onFinish={submitBranding}
                initialValues={auth.profile}
            >
                <Form.Item name='logo' label='Logo:'>
                    <Upload
                        maxCount={1}
                        fileList={fileList}
                        listType='picture'
                        beforeUpload={beforeUpload}
                        onRemove={() => setFileList([])}
                    >
                        <Button disabled={(fileList.length > 0)}><UploadOutlined />Upload</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name='organization_name' label='Organization Name:' >
                    <Input placeholder='My Cool Company' />
                </Form.Item>
                <Divider orientation="left" orientationMargin="0">
                    <Typography.Title level={4} style={{ margin: 0 }}>Theme</Typography.Title>
                </Divider>
                <Form.Item label='Color Scheme:' name="style_scheme" >
                    <Radio.Group>
                        <Radio.Button value="light"><SunFilled /> Light</Radio.Button>
                        <Radio.Button value="dark"><MoonFilled /> Dark</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Border:' name="style_border" >
                    <Radio.Group>
                        <Radio.Button value="rounded">Rounded</Radio.Button>
                        <Radio.Button value="straight">Straight</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Font Style:' name="style_font" >
                    <Radio.Group>
                        <Radio.Button value="serif">Serif</Radio.Button>
                        <Radio.Button value="sans-serif">Sans-Serif</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label='Time Format:' name="style_time" >
                    <Radio.Group>
                        <Radio.Button value="12hr">12 hr</Radio.Button>
                        <Radio.Button value="24hr">24 hr</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={formLoading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

export default Customize;
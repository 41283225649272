import { Badge, Button, Card, Divider, Flex, Form, Input, Typography } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { Logo } from '../components/logo';
import { AnyObject } from 'antd/es/_util/type';
import { useState } from 'react';

function LoginForm({
    submitAction,
    toggleForm
}: {
    submitAction(formData: AnyObject): void
    toggleForm(showLogin: boolean): void
}) {
    let [loginForm] = Form.useForm();

    return (
        <Form onFinish={submitAction} form={loginForm} validateTrigger='onFinish'>
            <Form.Item
                name={'email'}
                rules={[{ required: true, message: 'Email is required' }]}
            >
                <Input
                    placeholder='Email'
                />
            </Form.Item>
            <Form.Item
                name={'password'}
                rules={[{ required: true, message: 'Password is required' }]}
            >
                <Input.Password
                    placeholder='Password'
                />
            </Form.Item>
            <Flex justify='space-between'>
                <Form.Item>
                    <Button type='link' onClick={() => toggleForm(false)}>
                        Create an Account
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    );
}

function SignUpForm({
    submitAction,
    toggleForm
}: {
    submitAction(formData: AnyObject): void
    toggleForm(showLogin: boolean): void
}) {
    let [submitForm] = Form.useForm();

    return (
        <Form
            layout='vertical'
            onFinish={submitAction}
            form={submitForm}
        >
            <Form.Item
                name={'name'}
                label={'Name:'}
                rules={[{ required: true, message: 'Please enter a name' }]}
            >
                <Input
                    placeholder='Name'
                />
            </Form.Item>
            <Form.Item
                name={'email'}
                label={'Email:'}
                rules={[
                    {
                        type: 'email',
                        message: 'Please enter a valid email',
                    },
                    {
                        required: true,
                        message: 'Email is required'
                    },
                ]}
            >
                <Input
                    placeholder='email@example.com'
                />
            </Form.Item>
            <Form.Item
                name={'password'}
                label={'Password:'}
                rules={[
                    {
                        min: 6,
                        message: 'Must be at least 6 characters!',
                    },
                    {
                        required: true,
                        message: 'A password is required!',
                    },
                ]}
                style={{ marginBottom: 8 }}
            >
                <Input.Password
                    placeholder='Password'
                />
            </Form.Item>
            <Form.Item
                name={'confirm_password'}
                rules={[
                    {
                        required: true,
                        message: ''
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Passwords must match!'));
                        },
                    }),
                ]}
            >
                <Input.Password
                    placeholder='Confirm Password'
                />
            </Form.Item>
            <Flex justify='space-between'>
                <Form.Item>
                    <Button type='link' onClick={() => toggleForm(true)}>
                        Login
                    </Button>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Sign Up
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    );
}

function Login() {
    const { Text } = Typography;
    const [showLogin, setShowLogin] = useState(true);
    let navigate = useNavigate();
    let location = useLocation();
    let auth = useAuth();

    let from = location.state?.from?.pathname || "/dashboard";

    function handleLogin(formData: AnyObject) {
        auth.signInWithEmail(formData, () => {
            navigate(from, { replace: true });
        });
    }

    function handleSignUp(formData: AnyObject) {
        auth.signUpNewUser(formData, () => {
            navigate('/dashboard', { replace: true });
        });
    }

    return (
        <Flex style={{ height: "100vh" }} align='center' justify='center'>
            <Badge.Ribbon text="BETA">
                <Card>
                    <Flex vertical align='center'>
                        <Logo variant='light'/>
                        <Divider />
                        {showLogin
                            ? <LoginForm submitAction={handleLogin} toggleForm={setShowLogin} />
                            : <SignUpForm submitAction={handleSignUp} toggleForm={setShowLogin} />
                        }
                    </Flex>
                </Card>
            </Badge.Ribbon>
        </Flex>
    );
}


export default Login;
